
import React from 'react';

export class GithubRedirect extends React.Component {

    componentDidMount() {

        window.location.replace('https://www.github.com/hldprk/');

    }

    render() {

        return <span></span>;
    }

}