


import React from 'react';


export class LinkedInRedirect extends React.Component {

    componentDidMount() {

        window.location.replace('https://www.linkedin.com/in/holden-perkins-9102a4179/');

    }

    render() {

        return <span></span>
        
    }

}